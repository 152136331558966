<template>
  <div class="navigation-tag-container">
    <div
      id="navigation-tag"
      class="item-container"
      @wheel.stop="onwheel">
      <transition-group name="list" tag="div">
        <div
          class="tag-item"
          :class="{active:currentTag === item}"
          v-for="(item,index) in navigationTags"
          :key="item.fullPath"
          :tabindex="index"
          @click="toggleTag(item)">
          <span></span>
          <span>{{ $t(item.name) }}</span>
          <span @click.stop="deleteTag(index)">
            <em class="el-icon-close"></em>
          </span>
        </div>
      </transition-group>
    </div>
    <div class="close-btn">
      <el-dropdown
        trigger="click"
        @command="closeTag">
        <div style="padding: 10px">
          <em class="el-icon-arrow-down"></em>
        </div>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item
            v-for="item in dropdownItems"
            :key="item.type"
            :command="item.type"
            :disabled="!navigationTags.length">
            {{ $t(item.name) }}
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

export default {
  name: 'NavigationTag',
  data () {
    return {
      dropdownItems: [
        {
          name: 'closeAllTabs',
          type: 'all'
        }, {
          name: 'closeRightTabs',
          type: 'right'
        }, {
          name: 'closeLeftTabs',
          type: 'left'
        }, {
          name: 'closeOtherTabs',
          type: 'other'
        }
      ]
    }
  },
  computed: {
    ...mapState({
      navigationTags: 'navigationTags',
      currentTag: 'currentTag'
    })
  },
  created () {
    const currentTag = this.navigationTags.find(item => item?.fullPath === this.$route?.fullPath)
    this.setCurrentTag(currentTag)
  },
  methods: {
    ...mapMutations({
      setCurrentTag: 'setCurrentTag'
    }),
    onwheel (e) {
      /**
       * 监听导航条滚动,实现横向滚动
       */
      if (e.wheelDelta < 0) {
        e.currentTarget.scrollLeft += 20
      } else {
        e.currentTarget.scrollLeft -= 20
      }
    },
    deleteTag (index) {
      /**
       * 删除导航标签
       */
      const navigationTags = [...this.navigationTags]
      navigationTags.splice(index, 1)
      if (this.navigationTags[index] === this.currentTag) {
        if (navigationTags.length) {
          this.setCurrentTag(navigationTags[navigationTags.length - 1])
          this.$router.push({ path: this.currentTag?.fullPath })
        } else {
          this.setCurrentTag({})
          this.$router.push({ path: '/admin' })
        }
      }
      this.$store.commit('setNavigationTags', navigationTags)
    },
    toggleTag (item) {
      /**
       * 切换导航标签
       */
      if (item !== this.currentTag) {
        this.setCurrentTag(item)
        this.$router.push({ path: item?.fullPath })
      }
    },
    closeTag (type) {
      /**
       * 关闭导航标签
       */
      let index = 0
      let navigationTags = []
      switch (type) {
        case 'all':
          this.setCurrentTag(null)
          this.$router.push({ path: '/admin' })
          break
        case 'other':
          navigationTags.push(this.currentTag)
          break
        case 'left':
          index = this.navigationTags.findIndex(item => item === this.currentTag)
          navigationTags = this.navigationTags.slice(index)
          break
        default:
          index = this.navigationTags.findIndex(item => item === this.currentTag)
          navigationTags = this.navigationTags.slice(0, index + 1)
      }
      this.$store.commit('setNavigationTags', navigationTags)
    }
  },
  watch: {
    navigationTags (newValue, oldValue) {
      /**
       * 监听导航标签变动，如果是增加导航标签，
       * 增设置当前激活的导航标签为最后一个标签,
       * 并滑动导航标签到最后位置
       */
      if (newValue.length > oldValue.length) {
        this.setCurrentTag(newValue[newValue.length - 1])
      }
    },
    currentTag (newValue) {
      /**
       * 当前标签页变动后聚焦
       */
      this.$nextTick(() => {
        const index = this.navigationTags.findIndex(item => item?.fullPath === newValue?.fullPath)
        if (index !== -1) {
          const tagItems = this.$el.getElementsByClassName('tag-item')
          tagItems[index].focus()
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
::-webkit-scrollbar {
  display: none !important;
}

.navigation-tag-container {
  position: relative;
  height: 35px;
  width: 100%;

  .item-container {
    display: flex;
    align-items: center;
    box-sizing: border-box;
    height: 100%;
    width: calc(100% - 55px);
    margin: 0 10px;
    overflow-x: auto;

    > div:first-child {
      display: flex;
      align-items: center;

      .tag-item {
        flex-shrink: 0;
        display: inline-block;
        height: 25px;
        width: fit-content;
        line-height: 25px;
        text-align: center;
        margin-right: 10px;
        border-radius: 3px;
        outline: none;
        padding: 0 10px;
        font-size: 12px;
        background-color: #f6f6f6;
        cursor: pointer;
        transition: all .5s;
        // color: #909399;

        &:hover {
          background-color: #EBEEF5;
        }

        span {
          display: inline-block;
        }

        span:nth-child(1) {
          height: 8px;
          width: 8px;
          margin-right: 8px;
          border-radius: 5px;
          background-color: #DCDFE6;
        }

        span:nth-child(3) {
          margin-left: 8px;
          color: #C0C4CC;

          &:hover {
            color: #606266;
          }
        }
      }

      .active {
        span:nth-child(1) {
          background-color: $primary-color !important;
        }

        span:nth-child(2) {
          color: $primary-color;
        }
      }
    }
  }

  .close-btn {
    box-sizing: border-box;
    position: absolute;
    top: 0;
    right: 0;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    height: 34px;
    width: 35px;
    background-color: #f6f6f6;
    cursor: pointer;
    // box-shadow: 0 1px 2px 0 #E0E0E0;
  }
}

.list-enter,
.list-leave-to {
  opacity: 0;
  transform: translateX(-10px);
}

.list-move {
  transition: transform .5s;
}

.list-leave-active {
  position: absolute;
}
</style>
